<template>
    <div>
      <v-card v-if="!loading&!isError">
        <v-card-text class="text-h7 text-center " >
         サムネイルを元に戻しますか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#1DE9B6"   
            class="white--text"
            @click="save"
            >戻す
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
       <v-card  v-else-if="isError" >
          <DialogError
            v-on:closeErrorEmit="close"
            text="エラーが発生し,名前の変更が完了しませんでした。"
          />
       </v-card>
       <v-card v-else
          color="#4ECDC4"
          dark
        >
        <v-card-text>
          変更中...
          <br>

          <v-progress-linear
            indeterminate
            color="white"
            
          ></v-progress-linear>
        </v-card-text>
      </v-card>
     
    </div>
      
</template> 

<script>

const DialogError = () => import('@/components/DialogError.vue');
import axios from "axios"

export default {
  name: 'DialogThumbnailRestore',
  components:{
    DialogError
  },
  props: ["thumbnail","id","type"],
  data () {
    return {
      loading:false,
      isError:false,
      isComplete:false,
      altImg:require("@/assets/navi.jpg"),
    }
  },

  methods: {


    save (){
      this.loading = true;
      // console.log(`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.type}s/${this.id}/thumbnail`)
      axios({
        method: "POST",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.type}s/${this.id}/thumbnail`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"image/png"},
      }).then((response) => {
        if(response.data.results.status=="complete"){
            this.isComplete=true;
            this.loading = false;
            this.$emit('saveEmit', response.data.results.thumbnail)
        }else{
          this.loading = false;
          this.isError = true;
        }
      }).catch(reason => {
        console.log(reason)
        this.loading = false;
        this.isError = true;
      });
    
    },

    close (){
      this.isError = false;
      this.$emit('closeEmit')
    }
 
  },

}

</script>
